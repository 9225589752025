<template>
  <div>
    <div class="crumbs">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>
          <i class="el-icon-setting"></i> 日志管理
        </el-breadcrumb-item>
        <el-breadcrumb-item>报文日志</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <el-card shadow="always">
      <el-row :gutter="5">
        <el-col :span="6">
          <el-input
            clearable
            size="mini"
            v-model="datagramOrCnOrMnInput"
            placeholder="数据报文 / CN号 / MN号"
          >
            <template #prepend
              ><i class="el-icon-search"></i>输入搜索:</template
            >
          </el-input>
        </el-col>
        <div style="float: left">
          <el-button
            @click="handleClear"
            plain
            icon="el-icon-delete"
            size="mini"
          >
            清空</el-button
          >
          <el-button
            @click="handleSearch"
            plain
            type="primary"
            icon="el-icon-search"
            size="mini"
          >
            查询
          </el-button>
          <el-button
            @click="handleExport"
            plain
            type="warning"
            icon="el-icon-download"
            size="mini"
          >
            导出
          </el-button>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-row>
        <div class="block">
          <span class="demonstration">日期范围 </span>
          <el-date-picker
            v-model="date"
            @change="handleDateChange"
            type="datetimerange"
            size="mini"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
      </el-row>
    </el-card>
    <el-card shadow="always">
      <el-table :data="tableData" border style="width: 100%">
        <el-table-column type="index" :index="1"> </el-table-column>
        <el-table-column prop="enterTime" label="入库时间"> </el-table-column>
        <el-table-column prop="datagramTime" label="报文时间">
        </el-table-column>
        <el-table-column
          prop="datagram"
          label="数据报文"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
        <el-table-column prop="cn" label="数据类型">
          <template #default="scope">
            <span v-if="scope.row.cn === '2011'">实时数据</span>
            <span v-else-if="scope.row.cn === '2051'">分钟数据</span>
            <span v-else-if="scope.row.cn === '2061'">小时数据</span>
            <span v-else-if="scope.row.cn === '2031'">日数据</span>
            <span v-else>{{ scope.row.cn }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="mn" label="MN"> </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="pagination.currentPage"
          :page-sizes="[10, 20, 50, 100, 1000]"
          :page-size="pagination.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="pagination.total"
        >
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
import { exportDatagramLog } from "@/api/export";
import { getDatagramLog } from "@/api/log";

export default {
  name: "Datagram",
  data() {
    return {
      datagramOrCnOrMnInput: "",
      date: [
        new Date(new Date(new Date().toLocaleDateString()).getTime()),
        new Date(),
      ],
      tableData: [],
      pagination: {
        currentPage: 1,
        pageSize: 10,
        total: 0,
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    handleClear() {
      this.datagramOrCnOrMnInput = "";
    },
    handleSearch() {
      console.log(this.datagramOrCnOrMnInput);
      this.getList();
    },
    handleExport() {
      const params = {
        beginDateTime: new Date(this.date[0]).getTime(),
        endDateTime: new Date(this.date[1]).getTime(),
      };
      exportDatagramLog(params);
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pagination.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.pagination.currentPage = val;
      this.getList();
    },
    handleDateChange(val) {
      const beginDate = new Date(val[0]).format("yyyy-MM-dd hh:mm:ss");
      const endDate = new Date(val[1]).format("yyyy-MM-dd hh:mm:ss");
      this.date[0] = beginDate;
      this.date[1] = endDate;
      this.getList();
    },
    getList() {
      const params = {
        pageSize: this.pagination.pageSize,
        currentPage: this.pagination.currentPage,
        beginDate: new Date(this.date[0]).getTime(),
        endDate: new Date(this.date[1]).getTime(),
        datagramOrCnOrMnInput: this.datagramOrCnOrMnInput,
      };
      getDatagramLog(params).then((result) => {
        console.log(JSON.stringify(result));
        const data = result.data;
        this.pagination.total = data.total;
        this.pagination.pageSize = data.pageSize;
        this.pagination.currentPage = data.currentPage;
        this.tableData = data.list;
      });
    },
  },
};
</script>

<style scoped></style>
